import React from 'react';
import MainNav from '../MainNav/MainNav';
import './MainHeader.css';

function MainHeader() {
  return (
    <>
      <h1>4oh4.net</h1>
      <MainNav />
    </>
  );
}

export default MainHeader;
