import React from 'react';
import './MainFooter.css';

function MainFooter() {    
  return (
    <>
        <div className="main-footer">source code for this website is publicly available at <a href="https://github.com/githuborwhatever/4oh4.net">github</a></div>
    </>
  );
}

export default MainFooter;
