import React from 'react';

function Home() {    
  return (
    <>
    <h2>hello</h2>

      <p>
        this place is partially an excuse to play with web technologies although i also use it to host a few tools. sometimes i upload personal projects.
      </p>

      <p>
        if you want to contact me for any reason then you can email me. my name is eric and i own this domain. i'm sure you can put it together :)
      </p>
    </>
  );
}

export default Home;
