import React from 'react';

function Keyboards() {    
  return (
    <>
        <h2>keyboards</h2>
    </>
  );
}

export default Keyboards;
