import React from 'react';
import FalloutJunk from './FalloutJunk/FalloutJunk';

function Tabletop() {
  return (
    <>
        <h2>tabletop</h2>

        <FalloutJunk />
    </>
  );
}

export default Tabletop;
