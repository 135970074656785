import React from 'react';

function Blender() {    
  return (
    <>
        <h2>blender</h2>
    </>
  );
}

export default Blender;
