import React from 'react';

function Programs() {    
  return (
    <>
        <h2>programs</h2>
    </>
  );
}

export default Programs;
